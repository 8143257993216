
@import url('https://fonts.googleapis.com/css2?family=Audiowide&display=swap');


:root {
	/*--primary-color:#0a8ee6; */
  --timer-unit-color:#c4a371;
  --tile-color: #ebf17b;
  --tile-text: #2e20a6;
  --border-tile-color: #2e20a6;
  --header-background-color: #6153db;
  --footer-background-color: #274076;
  --text-secondary : #d5d273;
  --grad-stop-1 : #ed5a35;
  --grad-stop-2 : #e38b3e;
  --btn-color : #20a625;
  --btn-hover : #7ea620;
  --btn-text-color: #100e29;
	/* --secondary-color: #a3a3a3;
	--light-color: #f3f3f3;
	--text-highlight: #1e4b69;
	--on-mouse-over-color: #ffff00;
	--pill-color: #ffff00;
	--dark-color: #333;
	--btn-primary: #259934;
	--btn-primary-hov: #1eac31;
	--error-color: #c72727;
	--success-color: #7ec287;
	--form-error-bg: #d49d9d;
	--link-hover-color: #88ff00;
	--hover-color : #e6dda5;
	--max-width: 1100px; */
}


* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body, #root, .App{
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-image: linear-gradient(to bottom right, var(--grad-stop-1), var(--grad-stop-2));
}

.App {
  text-align: center;
}

.hidden {
  display: none;
}

 /* header style  */

 header {
  font-family: 'Audiowide', cursive;
  font-size: 40px;
  background-color: var(--header-background-color);
  padding: 10px;
 }

 header h1 {
  color: var(--text-secondary);
  padding: 0 20px;
 }

 .header-content {
  display: flex;
  justify-content: center;
 }

 .brain-logo {
  width: 5rem;
  height: 6rem;
 }

 .brain-logo img {
  width:100%;
  height:100%;
 }

  /* Main Styles  */

.game-text {
  color: #fff;
  padding-bottom: 20px;
}

/* main {
  height : 100%
} */

/* footer style  */

footer {
  background-color: var(--footer-background-color);
  color: #fff;
}

footer p {
  padding: 20px;
}


  /* Timer Styles  */


.timer-container {
  display: flex;
  justify-content: center;
}

/* .timer-grid {
  display: grid;
  width: 60%;
  grid-template-columns: 1fr 1fr 1fr 1fr;
} */

.timer-grid {
  display: flex;
}

.timer-grid div {
  background-color: var(--timer-unit-color);
  max-width: 100px;
  min-width: 80px;
  font-size: 5rem;
  margin: 5px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
}

.timer-grid p {
  max-width: 100px;
  min-width: 20px;
  font-size: 5rem;
  margin: 0;
}

  /* Game Tile Styles  */

.game-tiles {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.game-tile {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 5px;
  background-color: var(--tile-color);
  color : var(--tile-text);
  border-radius: 10px;
  cursor: pointer;
  border: solid 4px rgba(0,0,0,0);
}

.game-tile:hover {
  border-color: var(--border-tile-color);
}

.tile-image-container {
  width: 20rem;
  height: 15rem;
}

.tile-image {
  width: 100%;
  height: 100%;
  border-radius: 10px;
}

  /* Card Tile Styles  */

.card-grid-8 {
  justify-items: center;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
}

.card-grid-6 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
}

.card-grid-4 {
  justify-items: center;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.card-grid-2 {
  justify-items: center;
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.cardback {
  border-radius: 10px;
  /* border: solid rgba(0,0,0,0) 4px; */
  margin: 5px;
  width: 10rem;
  height: 10rem;
}

.cardback:hover {
  /* border-color: var(--border-tile-color); */
  cursor: pointer;
}

.cardback img {
  border: solid rgba(0,0,0,0) 4px;
  border-radius: 10px;
  width: 100%;
  height: 100%;
}

.cardback img:hover {
  border-color: var(--border-tile-color);
}


 /* dialog styles  */

 .backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.75);
  z-index: 10;
}

.modal {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  position: fixed;
  top: 20%;
  left: 20%;
  width: 60%;
  max-height: 90vh;
  background: white;
  border-radius: 5px;
  z-index: 100;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  overflow: hidden;
}

/* .modal .header {
  border-bottom: 1px solid #ccc;
} */

.modal .body {
  /* overflow-y: scroll; */
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: 80vh;
  min-height: 20vh;
}

 /* Buttons  */
.btn {
  border: 2px var(--border-tile-color) solid;
  border-radius: 10px;
  background: var(--btn-color);
  color: var(--btn-text-color);
  padding: 10px;
  font-size: 20px;
  margin: 6px;
  box-shadow: rgba(0, 0, 0, 0.9) 0 6px 6px 0;
}

.btn:hover {
  background: var(--btn-hover);
  transition: background-color 1s;
  transition: box-shadow .2s;
  box-shadow: rgba(0, 0, 0, 0.9) 0 1px 1px 0;
  cursor: pointer;
}